import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Select, Input, Button, Radio, Tooltip, message, Modal, Checkbox } from 'antd';
import { RocketOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { actions } from '../../redux/index';
import UploadFormItem from './UploadFormItem';
import AdFormItems from './AdFormItems';
import { upload as LANG } from '../../helpers/lan';
import axios from '../../helpers/axios';
import showError from '../../helpers/errorlist';
import { moduleList, moduleEnum } from '../../helpers/modules';
import HsFormItems from './HsFormItems';
import SvdFormItems from './SvdFormItems';
import SreFormItems from './SreFormItems';
import KpmFormItems from './KpmFormItems';
import GpFormItems from './GpFormItems';
import MsFormItems from './MsFormItems';
import MslFormItems from './MslFormItems';
import { removeItem } from '../../helpers/storage';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

class UploadForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      module: null,
      remainder: 'N/A',
    };
  }

  async componentDidMount() {
    try {
      this.props.onInitialUploadStatus();
      const res = await axios.get('/auth/remainders');
      const { remainder } = res.data;
      this.setState({ remainder });
      const { userStatus, expiryDate, lan } = this.props;
      if (userStatus !== 1) {
        message.error(LANG[lan].agreementMsg);
        return;
      } 
      if (dayjs().isAfter(dayjs(expiryDate))) {
        Modal.warning({ content: LANG[lan].expireMsg });
      }
    } catch (error) {
      showError(error, this.props.lan);
    }
  }

  handleSubmit = async (values) => {
    const { lan, isUploadingObj, obsTail, onChangeDefaultModuleConfig } = this.props;
    if (Object.values(isUploadingObj).reduce((res, v) => (res || v))) {
      message.warning(LANG[lan].uploadingMsg);
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      const files = {
        t1w: values.fileT1w && values.fileT1w[0].name,
        '2dt2': values.fileT22d && values.fileT22d[0].name,
        '3dt2': values.fileT23d && values.fileT23d[0].name,
        t1w_b: values.fileT1w_b && values.fileT1w_b[0].name,
        '3dt2_b': values.fileT23d_b && values.fileT23d_b[0].name,
        swi: values.fileSwi && values.fileSwi[0].name,
        dwi: values.fileDwi && values.fileDwi[0].name,
        cbf: values.fileCbf && values.fileCbf[0].name,
        dataF: values.fileDataF && values.fileDataF[0].name,
      };
      const obj = {
        ...values,
        files,
        obsTail,
        comment: values.comment || '',
        category: moduleList[values.module].group,
      };
      delete obj.fileT1w;
      delete obj.fileCbf;
      delete obj.fileT22d;
      delete obj.fileT23d;
      delete obj.fileT1w_b;
      delete obj.fileT23d_b;
      delete obj.fileSwi;
      delete obj.fileDwi;
      delete obj.fileDataF;

      await axios.post('/auth/tasks', obj);
      const defaultModuleConfig = { module: values.module, reportVersion: values.reportVersion };
      onChangeDefaultModuleConfig(defaultModuleConfig);
      // setItem('defaultModuleConfig', defaultModuleConfig);
      removeItem('obsTail');
      message.success(LANG[this.props.lan].successMsg);
      this.props.history.push('/task');
    } catch (error) {
      showError(error, this.props.lan);
      this.setState({ isSubmitting: false });
    }
  }

  handleChangeCurrentModule = async (module) => {
    const currentReportVersion = this.formRef.current.getFieldValue('reportVersion');
    if (moduleList[module].version.includes(currentReportVersion) === false) {
      this.formRef.current.setFieldsValue({ reportVersion: moduleList[module].version[moduleList[module].version.length - 1] });
    }
    this.props.onInitialUploadStatus();
    this.setState({ module });
  }

  handleChangeUploadStatus = (fileType, status) => {
    const { uploadStatusList } = this.state;
    const newState = { ...uploadStatusList };
    newState[fileType] = status;
    this.setState({ uploadStatusList: newState });
  }

  render() {
    const { lan, moduleArray, defaultModuleConfig, userStatus, expiryDate } = this.props;
    const LAN = LANG[lan];
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 8,
        },
      },
    };

    const initialValues = {
      module: moduleArray[0],
      // reportVersion: moduleList[moduleArray[0]].version.at(-1),
      reportVersion: '2.0',
      isResearchData: false,
      hasBaselineData: '1',      
    };    

    if (defaultModuleConfig?.module && Math.floor(moduleArray[0] / 100) === Math.floor(defaultModuleConfig.module / 100)) {
      initialValues.module = defaultModuleConfig.module;
      initialValues.reportVersion = defaultModuleConfig?.reportVersion;
    }

    const currentModule = Number(this.state.module || initialValues.module);
    const remainder = this.state.remainder[currentModule];
    const disableValue = remainder === 0 || userStatus !== 1 || dayjs().isAfter(dayjs(expiryDate));

    const QuotaItem = remainder ? (
      <FormItem label={LAN.remainderLabel} name="remainder">
        <span>{remainder}</span>
      </FormItem>
    ) : null;    
    
    const ExpiryDateItem = expiryDate ? (
      <FormItem label={LAN.expiryDateLabel} name="expiryDate">
        <span>{dayjs(expiryDate).format('YYYY-MM-DD HH:mm:ss')}</span>
      </FormItem>
    ) : null;  

    const T1wFormItem = (
      <UploadFormItem
        labelIndex="fileT1WLabel"
        field="fileT1w"
        isRequired
        filename="t1w.zip"
        disabled={disableValue}
      />
    );

    const DicomFormItem = (
      <UploadFormItem
        labelIndex="fileDataFLabel"
        field="fileDataF"
        isRequired
        filename="dataF.zip"
        disabled={disableValue}
      />
    );
    let FirstFormItem = T1wFormItem;
    let CategoryFormItems;

    switch (currentModule) {
      case moduleEnum.SBNPH:
      case moduleEnum.SBAD:
      case moduleEnum.SBFTD:
      case moduleEnum.FTDAD:
      case moduleEnum.NPH:
      case moduleEnum.FTD:
      case moduleEnum.AD: CategoryFormItems = <AdFormItems disabled={disableValue} />; break;
      case moduleEnum.SBHS:
      case moduleEnum.HS: CategoryFormItems = <HsFormItems disabled={disableValue} />; break;
      case moduleEnum.SBPD:
      case moduleEnum.PD: CategoryFormItems = null; break;
      case moduleEnum.SBSVD:
      case moduleEnum.SVD: CategoryFormItems = <SvdFormItems disabled={disableValue} />; break;
      case moduleEnum.SBMS:
      case moduleEnum.MS: CategoryFormItems = <MsFormItems disabled={disableValue} />; break;
      case moduleEnum.SBMSL:
      case moduleEnum.MSL: CategoryFormItems = <MslFormItems disabled={disableValue} module={currentModule} />; break;
      case moduleEnum.SRE: CategoryFormItems = <SreFormItems disabled={disableValue} />; break;
      case moduleEnum.KPM: CategoryFormItems = <KpmFormItems disabled={disableValue} />; break;
      case moduleEnum.GP: CategoryFormItems = <GpFormItems disabled={disableValue} />; break;
      case moduleEnum.MC:
      case moduleEnum.ICB:
      case moduleEnum.ICP:
      case moduleEnum.BH:        
      case moduleEnum.BHA: 
      case moduleEnum.SBMUL:       
      case moduleEnum.GEICB:
      case moduleEnum.GEICP: 
      case moduleEnum.HP_AD: 
      case moduleEnum.HP_PD:
      case moduleEnum.GEYL:
      case moduleEnum.GECOV: 
      case moduleEnum.BHAM: FirstFormItem = DicomFormItem; break;
      default: FirstFormItem = null; CategoryFormItems = null;
    }

    const IsResearchDataCheckbox = (currentModule > 300 ? null
      : (
        <FormItem
          wrapperCol={{ 
          offset: formItemLayout.labelCol.span, 
          span: formItemLayout.wrapperCol.span,
        }}
      >
          <FormItem
            name="isResearchData"
            key="isResearchData"
            valuePropName="checked"            
            noStyle
        >
            <Checkbox>{LAN.isResearchDataLabel}</Checkbox>
          </FormItem>
          <Tooltip title={LAN.aboutResearchDataContent}>
            <a href="#aboutResearchData" style={{ margin: '0 1em' }}>
              {LAN.aboutResearchData}
            </a>
          </Tooltip>
        </FormItem>
        )
    );

    return (
      <Form onFinish={this.handleSubmit} {...formItemLayout} name="upload-form" ref={this.formRef} initialValues={initialValues}>
        <FormItem
          label={LAN.moduleSelect}
          name="module"
          rules={
            [{
              required: true, message: LAN.diseaseRequiredMsg,
            }]
          }
        >
          <Select onChange={this.handleChangeCurrentModule}>
            {moduleArray.map((key) => (
              <Option value={key} key={key}>{moduleList[key][lan]}</Option>
            ))}
          </Select>
        </FormItem>
        {QuotaItem}
        {ExpiryDateItem}
        {FirstFormItem}
        {CategoryFormItems}        
        <FormItem
          label={LAN.reportVersionLabel}
          hidden={moduleList[currentModule].version.length === 1}
        >
          <FormItem
            name="reportVersion"
            key="reportVersion"            
            noStyle
          >
            <Radio.Group buttonStyle="solid">
              {moduleList[currentModule].version.map((ver) => (<Radio.Button value={ver} key={`rvr${ver}`}>V{ver}</Radio.Button>))}
            </Radio.Group>
          </FormItem>
          <Tooltip title={LAN.AboutVersionContent}>
            <a href="#aboutversion" style={{ margin: '0 1em' }}>
              {LAN.AboutVersion}
            </a>
          </Tooltip>
        </FormItem>
        {IsResearchDataCheckbox}
        <FormItem label={LAN.commentLabel} name="comment" hidden={currentModule > 600}>
          <TextArea />
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button
            type="primary"
            icon={<RocketOutlined />}
            htmlType="submit"
            loading={this.state.isSubmitting}
            disabled={disableValue}
          >
            {LAN.submitBtn}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

UploadForm.propTypes = {
  moduleArray: PropTypes.array.isRequired,
  onInitialUploadStatus: PropTypes.func.isRequired,
  isUploadingObj: PropTypes.object.isRequired,
  obsTail: PropTypes.string.isRequired,  
  expiryDate: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lan: state.lan,
  isUploadingObj: state.isUploadingObj,
  obsTail: state.obsTail,
  userStatus: state.userStatus,
  defaultModuleConfig: state.defaultModuleConfig,
  expiryDate: state.expiryDate,
});

const mapDispatchToProps = (dispatch) => ({
  onInitialUploadStatus: () => { dispatch(actions.initialUploadStatus()); },
  onChangeDefaultModuleConfig: (obj) => { dispatch(actions.changeDefaultModuleConfig(obj)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadForm));
